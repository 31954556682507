import { useStore } from "@/lib/useStore";
import { Actions, Getters } from "@/store/modules/vimbApi/types";
import { Modules } from "@/store/types";

const store = useStore(Modules.VIMB_API);

const useActions = () => store.useActions([Actions.LOAD_ME]);

const useComputed = () => store.useComputed([Getters.VERMITTLERNUMBER]);

export const useUser = () => {
  const actions = useActions();
  const computed = useComputed();

  return {
    ...actions,
    ...computed
  };
};
