





















import { computed, defineComponent, ref, toRefs } from "@vue/composition-api";
import Pdf from "vue-pdf";

const downloadLink = (url: string, name?: string) => {
  const a = document.createElement("a");
  a.href = url;
  if (name) a.download = name;
  else a.target = "_BLANK";
  return a;
};

const downloadPdf = (name?: string, buffer?: ArrayBuffer) => {
  if (!buffer) return;
  const blob = new Blob([buffer], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);
  downloadLink(url, name).click();
  setTimeout(() => window.URL.revokeObjectURL(url), 1000);
};

export enum Ratio {
  DINA4 = 210 / 297
}

export default defineComponent({
  components: { Pdf },
  props: {
    loading: { type: Boolean, default: false },
    pdf: { type: ArrayBuffer },
    printDpi: { type: Number, default: 100 }
  },
  setup(props) {
    const { pdf } = toRefs(props);
    const pages = ref<number>(1);
    const src = computed(() => pdf?.value && new Uint8Array(pdf?.value));
    const refs = ref();
    const download = (name: string) => downloadPdf(name, pdf?.value);
    const print = (name: string) => downloadPdf(name, pdf?.value);
    return { src, pages, refs, download, print, Ratio };
  }
});
