import { render, staticRenderFns } from "./Pdf.vue?vue&type=template&id=10238357&scoped=true&"
import script from "./Pdf.vue?vue&type=script&lang=ts&"
export * from "./Pdf.vue?vue&type=script&lang=ts&"
import style0 from "./Pdf.vue?vue&type=style&index=0&id=10238357&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10238357",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VResponsive,VSkeletonLoader})
