import { Actions, Getters } from "@/store/modules/maklerdokumente/types";
import { Modules } from "@/store/types";
import { useStore } from "../useStore";

const store = useStore(Modules.MAKLERDOKUMENTE);

const useActions = () =>
  store.useActions([Actions.VALIDATE_DOCUMENT, Actions.LOAD_DOCUMENT]);

const useGetters = () =>
  store.useGetters([Getters.GET_VALIDATION, Getters.GET_DOCUMENT]);

export const useMaklerdokumente = () => {
  const actions = useActions();
  const getters = useGetters();

  return {
    ...actions,
    ...getters
  };
};
