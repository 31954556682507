








import { computed, defineComponent, toRefs } from "@vue/composition-api";

export default defineComponent({
  props: {
    error: { type: Object, default: () => ({}) }
  },
  setup(props) {
    const { error } = toRefs(props);
    const errors = computed(() =>
      Object.values(error.value)
        .flat()
        .map(error => error.replace("=>", ": "))
    );
    return { errors };
  }
});
